import { styled } from "~/theme";

export const ContentLayout = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "$24",
  padding: "$24",
  minHeight: "calc(100vh - 32px)",
});

export const MainContent = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",

  variants: {
    justify: {
      centered: { justifyContent: "center", alignItems: "center" },
      centeredFull: { justifyContent: "center", alignItems: "center" },
      bleed: {},
    },
  },
});

export const Header = styled("header", {
  alignItems: "start",

  variants: {
    navigationLayout: {
      flex: {
        display: "flex",
        justifyContent: "space-between",
      },
      grid: {
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
      },
    },
  },

  "> *:first-child": {
    justifySelf: "start",
  },
  "> *:last-child": {
    justifySelf: "end",
  },
});

export const PlainHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$8",
  padding: "$8",
});

export const LargeContentHeader = styled("div", {
  justifySelf: "stretch",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: "$8",
  maxWidth: "650px",
});

export const ContentFooterSteps = styled("footer", {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
});

export const ContentFooterStepsItem = styled("div", {
  width: "90px",
  height: "6px",
  borderRadius: "3px",

  variants: {
    active: {
      true: {
        background: "$primary-violet",
      },
      false: {
        background: "$grey-800",
      },
    },
  },
});
