import type { IconProps } from "./types";

function User({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.5764 18.8558L19.0956 19.397C19.3917 19.1128 19.4045 18.6435 19.1243 18.3436L18.5764 18.8558ZM5.42358 18.8557L4.87566 18.3436C4.59543 18.6434 4.6082 19.1128 4.90432 19.3969L5.42358 18.8557ZM18.0571 18.3146C16.4843 19.8237 14.3511 20.75 12 20.75V22.25C14.7535 22.25 17.2547 21.1633 19.0956 19.397L18.0571 18.3146ZM12 20.75C9.64887 20.75 7.51567 19.8237 5.94284 18.3146L4.90432 19.3969C6.74524 21.1633 9.2465 22.25 12 22.25V20.75ZM5.9715 19.3679C7.47814 17.756 9.62099 16.75 11.9999 16.75V15.25C9.18853 15.25 6.65409 16.4409 4.87566 18.3436L5.9715 19.3679ZM11.9999 16.75C14.3789 16.75 16.5218 17.756 18.0284 19.3679L19.1243 18.3436C17.3459 16.4409 14.8114 15.25 11.9999 15.25V16.75Z"
        fill="currentColor"
      />
      <circle cx="12" cy="12" r="9.5" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="12" cy="10" r="3" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}
export default User;
