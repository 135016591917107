export function AITwinIcon({ size = "24" }: { size?: number | string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="10.5" r="1" fill="currentColor" />
      <circle cx="15" cy="10.5" r="1" fill="currentColor" />
      <path
        d="M12.0002 16C11.1042 16 10.2999 15.6072 9.75019 14.9844C9.49854 14.6992 9.30023 14.3659 9.1709 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.1709 14C9.30023 14.3659 9.49854 14.6992 9.75019 14.9844C10.2999 15.6072 11.1042 16 12.0002 16C12.8962 16 13.7005 15.6072 14.2502 14.9844C14.5019 14.6992 14.7002 14.3659 14.8295 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="9.5"
        cy="9.5"
        r="9.5"
        transform="matrix(-1 0 0 1 21.5 2.5)"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
    </svg>
  );
}
