import { useFeatureFlag, useFeatureFlagIfEligible } from "feature-flags";
import { t } from "i18next";
import Link from "next/link";
import { useRouter } from "next/router";

import { Text } from "~/components";
import AIAdsIcon from "~/components/icons/AIAds";
import { AITwinIcon } from "~/components/icons/AITwin";
import { API as ApiIcon } from "~/components/icons/API";
import { CalendarIcon } from "~/components/icons/Calendar";
import CaptionsDesktopLogo from "~/components/icons/CaptionsDesktopLogoMedium";
import CaptionsLogoSmall from "~/components/icons/CaptionsLogoSmall";
import { FilmStripMultipleIcon } from "~/components/icons/FilmStripMultipleIcon";
import { FilmStripSwatchIcon } from "~/components/icons/FilmStripSwatch";
import LinkOut from "~/components/icons/LinkOut";
import { StackIcon } from "~/components/icons/Stack";
import UserIcon from "~/components/icons/User";
import { IntercomHelpButton } from "~/components/IntercomeHelpButton";
import { Tooltip } from "~/components/Tooltip";
import { Tweaks } from "~/components/TweaksWidget/TweaksWidget";
import useMediaQuery from "~/hooks/useMediaQuery";

import {
  LayoutContainer,
  Sidebar,
  MainContent,
  SidebarSection,
  SidebarLink,
  SidebarFooter,
  SidebarHeader,
  SidebarLinkIcon,
  MainContentContainer,
  SidebarMobileHeader,
} from "./StudioAppLayout.styles";

export function StudioAppLayout(props: React.PropsWithChildren) {
  const enableSocialStudio = useFeatureFlag("enable_social_studio");
  const enableAdsStudioNav = useFeatureFlag("enable_ads_studio_nav");
  const enableAdsStudio = useFeatureFlag("enable_ads_studio");
  const showAdsStudioLandingPage = useFeatureFlagIfEligible(
    "show_ads_studio_landing_page",
    () => enableAdsStudioNav && !enableAdsStudio
  );
  const enableAiTwin = useFeatureFlag("enable_ai_twin");

  return (
    <LayoutContainer>
      <Tweaks />
      <Sidebar>
        <SidebarHeader>
          <Link href="/">
            <CaptionsDesktopLogo />
          </Link>
        </SidebarHeader>
        <SidebarMobileHeader>
          <Link href="/">
            <CaptionsLogoSmall size={24} />
          </Link>
        </SidebarMobileHeader>
        <SidebarSectionItemList
          items={[
            {
              title: t("common:studio-layout.projects"),
              href: "/projects",
              icon: <FilmStripMultipleIcon />,
            },
          ]}
        />
        {(enableAdsStudioNav || enableSocialStudio || showAdsStudioLandingPage) && (
          <SidebarSectionItemList
            title={t("common:studio-layout.workflows-section.title")}
            items={[
              enableAdsStudioNav && {
                title: t("common:studio-layout.workflows-section.items.ads-studio"),
                href: showAdsStudioLandingPage ? "/studio/ads/demo" : "/studio/ads",
                icon: <AIAdsIcon />,
              },
              enableSocialStudio && {
                title: t("common:studio-layout.workflows-section.items.social-studio"),
                href: "/studio/social",
                icon: <CalendarIcon />,
              },
              //  {
              //   title: "Content Studio",
              //   href: "/studio/content",
              //   icon: <StackIcon />,
              // },
            ]}
          />
        )}
        {(enableAiTwin || enableSocialStudio) && (
          <SidebarSectionItemList
            title={t("common:studio-layout.team-library-section.title")}
            items={[
              enableAiTwin && {
                title: t("common:studio-layout.team-library-section.items.ai-twins"),
                href: "/ai-twin",
                icon: <AITwinIcon />,
              },
              enableSocialStudio && {
                title: "Brand Kit",
                href: "/library/brand-kit",
                icon: <FilmStripSwatchIcon />,
              },
            ]}
          />
        )}
        <SidebarFooter>
          <SidebarSectionItemList
            title={t("common:studio-layout.account-section.title")}
            items={[
              {
                title: t("common:studio-layout.account-section.items.profile"),
                href: "/profile/account",
                icon: <UserIcon />,
              },
              {
                title: t("common:studio-layout.account-section.items.api-dashboard"),
                href: "/api",
                icon: <ApiIcon />,
              },
            ]}
          />
          <IntercomHelpButton />
        </SidebarFooter>
      </Sidebar>
      <MainContentContainer fillColumn>
        <MainContent>{props.children}</MainContent>
      </MainContentContainer>
    </LayoutContainer>
  );
}

type SidebarItemParams = {
  title: string;
  icon: React.ReactNode;
  href: string;
};

function SidebarSectionItemList({
  title,
  items,
}: {
  title?: string;
  items: (SidebarItemParams | false)[];
}) {
  return (
    <SidebarSection>
      {title && (
        <Text as="h3" variant="body-3-caps" color="grey-500">
          {title}
        </Text>
      )}
      {(items.filter(Boolean) as SidebarItemParams[]).map((item) => (
        <LinkItem
          title={item.title}
          icon={<SidebarLinkIcon>{item.icon}</SidebarLinkIcon>}
          href={item.href}
          key={item.href}
        />
      ))}
    </SidebarSection>
  );
}

function LinkItem({ title, icon, href }: SidebarItemParams) {
  const router = useRouter();
  const isCollapsed = useMediaQuery("(max-width: 990px)");

  const isExternalLink = href.includes("://");

  return (
    <Tooltip unWrapChildren tooltipText={isCollapsed ? title : undefined} side="right">
      <SidebarLink
        href={href}
        key={href}
        active={router.asPath.startsWith(href)}
        target={isExternalLink ? "_blank" : undefined}
      >
        {icon ?? <div style={{ width: "24px", height: "24px" }} />}
        <Text>{title}</Text>
        {isExternalLink && (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "4px",
              lineHeight: 0,
            }}
          >
            <LinkOut css={{ color: "$grey-500" }} />
          </div>
        )}
      </SidebarLink>
    </Tooltip>
  );
}
