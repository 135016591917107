import { IconProps } from "./types";

function AIAds({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.5 11V18C20.5 19.6569 19.1569 21 17.5 21H6.5C4.84315 21 3.5 19.6569 3.5 18V6C3.5 4.34315 4.84315 3 6.5 3H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.25 15.25H4.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 8.75H4.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75 3.75V20.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5469 2.47082C17.7265 2.0861 18.2735 2.0861 18.4531 2.47082L19.1959 4.06246C19.2456 4.16888 19.3311 4.25443 19.4375 4.30409L21.0292 5.04691C21.4139 5.22647 21.4139 5.77353 21.0292 5.95309L19.4375 6.69591C19.3311 6.74557 19.2456 6.83112 19.1959 6.93754L18.4531 8.52918C18.2735 8.9139 17.7265 8.9139 17.5469 8.52918L16.8041 6.93754C16.7544 6.83112 16.6689 6.74557 16.5625 6.69591L14.9708 5.95308C14.5861 5.77353 14.5861 5.22647 14.9708 5.04691L16.5625 4.30409C16.6689 4.25443 16.7544 4.16888 16.8041 4.06246L17.5469 2.47082Z"
        fill="currentColor"
      />
      <path
        d="M15.8462 18V14.7692C16.7436 14.7692 18 15.0923 18 16.3846C18 17.6769 16.7436 18 15.8462 18Z"
        stroke="currentColor"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 18L12.6154 14.7692L14.2308 18"
        stroke="currentColor"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5386 17.1923H13.6924"
        stroke="currentColor"
        strokeWidth="1.15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AIAds;
