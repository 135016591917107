import { useCallback } from "react";

import { INTERCOM_OPEN } from "~/constants/mixpanel.constants";
import { useIntercom } from "~/context/IntercomContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { StudioButton } from "~/modules/studio/StudioButton";
import { styled } from "~/theme";

import HelpIcon from "./icons/Help";
import { Tooltip } from "./Tooltip";

export function IntercomHelpButton() {
  const { track } = useAnalytics();
  const { visible, show: showIntercom, hide: hideIntercom, update: updateIntercom } = useIntercom();

  const handleIntercomClick = useCallback(() => {
    // by default right now, intercom is aligned to the right
    // i didn't want to change the default behavior, since
    // some of the buttons may show it on the right
    updateIntercom({
      alignment: "left",
    });

    if (!visible) {
      track(INTERCOM_OPEN);
      showIntercom();
    } else {
      hideIntercom();
    }
  }, [showIntercom, hideIntercom, updateIntercom, visible, track]);

  return (
    <IntercomContainer>
      <Tooltip tooltipText="Help Center" unWrapChildren>
        <div style={{ width: "min-content" }}>
          <StudioButton
            variant={"secondary"}
            onClick={handleIntercomClick}
            size="sm"
            icon={<HelpIcon />}
            iconPosition="only"
          />
        </div>
      </Tooltip>
    </IntercomContainer>
  );
}

const IntercomContainer = styled("div", {
  "@bp1-sidebar": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
