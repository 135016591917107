import { styled } from "~/theme";

function LinkOut({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.54 13.5011L20.2408 3.80038"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.5382 8.60941L20.5382 4.50313C20.5382 3.95084 20.0905 3.50313 19.5382 3.50313L15.4319 3.50313"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.6444 5H6C4.34314 5 3 6.34315 3 8V18C3 19.6569 4.34315 21 6 21H16C17.6569 21 19 19.6569 19 18V13.3556"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(LinkOut, {
  color: "$grey-200",
  height: "24px",
  width: "24px",
});
