import Link from "next/link";
import { Fragment } from "react";

import { Text } from "~/components";
import ArrowLeft from "~/components/icons/ArrowLeft";
import { MobileBlocker } from "~/components/MobileBlocker";
import { StudioButton } from "~/modules/studio/StudioButton";

import {
  ContentLayout,
  PlainHeader,
  Header,
  MainContent,
  LargeContentHeader,
  ContentFooterSteps,
  ContentFooterStepsItem,
} from "./StudioContentLayout.styles";
import { StudioLayoutProvider } from "./StudioLayoutContext";
import {
  StudioContentLayoutFooterAccessory,
  StudioContentLayoutJustification,
  StudioContentLayoutNavigation,
} from "./types";

interface StudioContentLayoutProps {
  children: React.ReactNode;

  icon?: React.ReactNode;
  title?: string;
  badge?: React.ReactNode;
  description?: string;
  action?: React.ReactNode;

  // defaults to entrypoint
  navigation?: StudioContentLayoutNavigation;

  justify?: StudioContentLayoutJustification;
  footerAccessory?: StudioContentLayoutFooterAccessory;

  blockMobile?: boolean;
}

export function StudioContentLayout({
  justify = "centered",
  footerAccessory,
  children,
  blockMobile = false,
  ...headerProps
}: StudioContentLayoutProps) {
  return (
    <StudioLayoutProvider justify={justify}>
      {blockMobile && <MobileBlocker />}
      <ContentLayout>
        <ContentHeader {...headerProps} />
        <MainContent justify={justify}>{children}</MainContent>
        <ContentFooter accesory={footerAccessory} />
      </ContentLayout>
    </StudioLayoutProvider>
  );
}

function ContentHeader({
  icon,
  title,
  badge,
  description,
  action,
  navigation = { type: "plain" },
}: Omit<StudioContentLayoutProps, "children">) {
  if (navigation.type === "plain" && !title && !icon && !badge) {
    return <></>;
  }
  return (
    <Header navigationLayout={navigation.type === "plain" ? "flex" : "grid"}>
      {navigation.type === "plain" ? (
        <Fragment>
          <PlainHeader>
            {icon}
            <Text>{title}</Text>
            {badge}
          </PlainHeader>
        </Fragment>
      ) : (
        <Fragment>
          {navigation.type === "embedded" ? (
            <StudioButton
              onClick={navigation.onBack}
              variant="secondary"
              icon={<ArrowLeft />}
              iconPosition="only"
            />
          ) : (
            <StudioButton
              as={Link}
              href={navigation.backLink}
              variant="secondary"
              icon={<ArrowLeft />}
              iconPosition="only"
            />
          )}
          <StudioLargeContentHeader title={title} description={description} />
        </Fragment>
      )}
      <div>{action}</div>
    </Header>
  );
}

export function StudioLargeContentHeader({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) {
  return (
    <LargeContentHeader>
      <Text variant="heading-3" color="grey-100">
        {title}
      </Text>
      {description && (
        <Text variant="heading-4-medium" color="grey-400">
          {description}
        </Text>
      )}
    </LargeContentHeader>
  );
}

function ContentFooter({ accesory }: { accesory?: StudioContentLayoutFooterAccessory }) {
  if (accesory?.type === "steps") {
    return (
      <ContentFooterSteps>
        {Array.from({ length: accesory.total }).map((_, index) => (
          <ContentFooterStepsItem key={index} active={accesory.current - 1 === index} />
        ))}
      </ContentFooterSteps>
    );
  }

  return null;
}
