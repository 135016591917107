import Link from "next/link";

import { ScrollArea } from "~/components/ScrollArea";
import { styled } from "~/theme";

export const LayoutContainer = styled("div", {
  display: "flex",
  height: "100vh",
  overflow: "hidden",
});

export const Sidebar = styled("aside", {
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "$16",
  width: "250px",
  padding: "$16",
  height: "100vh",

  "@bp1-sidebar": {
    width: "auto",
    flexShrink: 1,
  },
});

export const SidebarHeader = styled("header", {
  padding: "$8",

  "& > a": {
    display: "block",
    lineHeight: 0,
  },

  "@bp1-sidebar": {
    display: "none",
  },
});

export const SidebarMobileHeader = styled("header", {
  display: "none",
  alignItems: "center",
  justifyContent: "center",
  margin: "$16 0",

  "& > a": {
    display: "block",
    lineHeight: 0,
    color: "white",
  },

  "@bp1-sidebar": {
    display: "flex",
  },
});

export const SidebarSection = styled("section", {
  display: "flex",
  flexDirection: "column",
  gap: "2px",

  "@bp1-sidebar": {
    "& > h3": {
      display: "none",
    },
  },
});

export const SidebarLink = styled(Link, {
  display: "flex",
  alignItems: "center",
  gap: "$8",
  padding: "$8",
  borderRadius: "$8",
  color: "$grey-300",
  "&:hover": {
    color: "$grey-300",
    backgroundColor: "$grey-800",
  },

  variants: {
    active: {
      true: {
        color: "$grey-300",
        backgroundColor: "$grey-800",
      },
    },
  },

  "@bp1-sidebar": {
    "& > *:not(:first-child)": {
      display: "none",
    },
  },
});

export const SidebarLinkIcon = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  lineHeight: 0,
});

export const SidebarFooter = styled("footer", {
  display: "flex",
  flexDirection: "column",
  gap: "$24",
  marginTop: "auto",
  marginBottom: "$8",
});

export const MobileDivider = styled("div", {
  display: "none",

  width: "calc(100% - 8px)",
  height: "1px",
  backgroundColor: "$grey-800",
  margin: "-$16 0",
  marginLeft: "$4",

  "@bp1-sidebar": {
    display: "block",
  },
});

export const MainContentContainer = styled(ScrollArea, {
  flex: 1,
  overflowY: "scroll",
  padding: "$16 $16 $16 0",
});

export const MainContent = styled("div", {
  backgroundColor: "$grey-950",
  borderRadius: "$24",
  minHeight: "calc(100vh - 32px)",
});
