export function FilmStripSwatchIcon({ size = "24" }: { size?: number | string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 13V13C20.6569 13 22 14.3431 22 16L22 18C22 19.6569 20.6569 21 19 21L12.5 21L7.75 21"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.3085 18.6725C12.0156 18.9654 12.0156 19.4402 12.3085 19.7331C12.6014 20.026 13.0763 20.026 13.3692 19.7331L12.3085 18.6725ZM18.3189 6.65166L19.7331 8.06587L20.7938 7.00521L19.3796 5.591L18.3189 6.65166ZM14.0763 7.71232L15.1369 6.65166L14.0763 5.591L13.0156 6.65166L14.0763 7.71232ZM13.2808 8.50781L14.0763 7.71232L13.0156 6.65166L12.2201 7.44715L13.2808 8.50781ZM19.7331 11.2479L15.4905 15.4905L16.5511 16.5512L20.7938 12.3085L19.7331 11.2479ZM15.4905 15.4905L12.3085 18.6725L13.3692 19.7331L16.5511 16.5512L15.4905 15.4905ZM19.3796 5.591C17.9151 4.12653 15.5407 4.12653 14.0763 5.591L15.1369 6.65166C16.0156 5.77298 17.4402 5.77298 18.3189 6.65166L19.3796 5.591ZM19.7331 8.06587C20.6118 8.94455 20.6118 10.3692 19.7331 11.2479L20.7938 12.3085C22.2582 10.844 22.2582 8.46968 20.7938 7.00521L19.7331 8.06587Z"
        fill="currentColor"
      />
      <rect
        x="13"
        y="3"
        width="18"
        height="10"
        rx="3"
        transform="rotate(90 13 3)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M7.25 3V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 9H7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 15H7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
