import { createContext, useContext } from "react";

import { StudioContentLayoutJustification } from "./types";

interface StudioLayoutContextType {
  justify: StudioContentLayoutJustification;
}

const StudioLayoutContext = createContext<StudioLayoutContextType | null>(null);

export function StudioLayoutProvider({
  justify,
  children,
}: React.PropsWithChildren<StudioLayoutContextType>) {
  return (
    <StudioLayoutContext.Provider value={{ justify }}>{children}</StudioLayoutContext.Provider>
  );
}

export function useStudioLayout() {
  const context = useContext(StudioLayoutContext);
  if (!context) {
    throw new Error("useStudioLayout must be used within a StudioLayoutProvider");
  }

  return context;
}
